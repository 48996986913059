<script>
export default {
  name: 'WinnerPrizeStatus',
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  computed: {
    statusData() {
      const data = {
        variant: 'secondary',
        label: 'Não Iniciado',
      }
      if (this.status === 'not_found') {
        data.variant = 'danger'
        data.label = 'Não encontrado'
      }
      if (this.status === 'incomplete') {
        data.variant = 'warning'
        data.label = 'Incompleto'
      }
      if (this.status === 'finished') {
        data.variant = 'success'
        data.label = 'Finalizado'
      }

      return data
    },
  },
}
</script>

<template>
  <div>
    <b-badge
      :variant="statusData.variant"
    >
      {{ statusData.label }}
    </b-badge>
  </div>
</template>

<style scoped>

</style>
