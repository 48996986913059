const DocumentStatus = [
  {
    value: 'pending',
    text: 'Não entregue',
  },
  {
    value: 'done',
    text: 'Entregue',
  },
]
export default DocumentStatus
