<script>
export default {
  name: 'I2SaveButton',
  props: {
    label: {
      type: String,
      required: false,
      default: 'Salvar',
    },
  },
}
</script>

<template>
  <b-button
    v-bind="$attrs"
    v-on="$listeners"
  >
    <feather-icon icon="SaveIcon"/>
    {{ label }}
  </b-button>
</template>

<style scoped>

</style>
