<template>
  <b-modal
    v-model="showModal"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    no-close-on-esc
    centered
    ok-only
    ok-title="Fechar"
    size="xl"
    title="Documentos"
    @hidden="onClose"
    @hidde="onClose"
  >
    <h4>Lista de documentos do vencedor {{ getName() }}.</h4>
    <b-card>
      <b-form>
        <validation-observer
          ref="refFormObserver"
        >
          <b-row>
            <b-col sm="12" md="6">
              <i2-form-vue-select
                v-model="document"
                multiple
                :options="DocumentTypes"
                rules="required"
                name="document" label="documento"
              />
            </b-col>
            <b-col sm="12" md="6">
              <i2-form-input-file
                v-model="file"
                name="arquivo"
                label="Arquivo"
                accept="image/*, .pdf"
                rules="required"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" class="text-right">
              <i2-save-button variant="primary" @click="onAddDocument"/>
            </b-col>
          </b-row>
        </validation-observer>
      </b-form>

    </b-card>

    <b-table
      responsive="sm"
      :fields="fields"
      :items="documents"
      bordered
    >
      <template
        #cell(file)="data"
      >
        <div class="rounded border p-2 text-center cursor-pointer" style="width: 60px" @click="onShowGalery(data.item)">
          <i v-if="data.item.type==='pdf'" class="far fa-file-pdf" ></i>
          <feather-icon v-if="data.item.type !=='pdf'" icon="ImageIcon"/>
        </div>
      </template>

      <template
        #cell(action)="data"
        style="width: 20%"
      >
        <div class="px-1 text-center">
          <b-button-group>
            <i2-icon-button
              icon="TrashIcon"
              variant="outline-danger"
              @click="onDelete(data.item)"
            />
          </b-button-group>
        </div>
      </template>
    </b-table>

  </b-modal>

</template>
<script>
import { BButtonGroup, BTable } from 'bootstrap-vue'
import DocumentTypes from '@/variables/DocumentTypes'
import I2FormInputFile from '@/components/i2/forms/elements/I2FormInputFile.vue'
import DocumentStatus from '@/variables/DocumentStatus'
import I2SaveButton from '@/components/i2/forms/elements/I2SaveButton.vue'
import { ValidationObserver } from 'vee-validate'

export default {
  name: 'PrizeDrawWinnerDocumentModal',
  components: {
    BButtonGroup,
    I2SaveButton,
    I2FormInputFile,
    BTable,
    ValidationObserver,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      currentDocuments: [],
      showGallery: false,
      showModal: false,
      winner: null,
      file: null,
      status: 'pending',
      fields: [
        {
          key: 'documents',
          label: 'Documento',
          class: 'w-70',
        },
        {
          key: 'file',
          label: 'Imagem',
          class: 'w-15',
        },
        {
          key: 'action',
          label: 'Ação',
          class: 'w-15',
        },
      ],
      document: null,
      documents: [],
      DocumentTypes,
      DocumentStatus,
    }
  },
  watch: {
    value(val) {
      this.showModal = val != null
      if (val) {
        this.winner = val
        this.getDocuments()
      }
    },
  },
  mounted() {
    this.showModal = this.value != null
    this.status = this.DocumentStatus.find(item => item.value === 'pending')
    if (this.value) {
      this.winner = this.value
      this.getDocuments()
    }
  },
  methods: {
    getName() {
      return this.winner?.person.name
    },
    async onAddDocument() {
      const isValid = await this.$refs.refFormObserver.validate()
      if (!isValid) {
        return false
      }
      let data = new FormData()
      let documents = this.document.forEach(item => {
        data.append('document[]', item.value)
      })

      data.append('file', this.file)

      let response = await this.$http.post(`winners/${this.winner.uuid}/documents`, data)

      if (response.error) {
        this.$notify.error('Não foi possível incluir documento.')
        return false
      }
      this.$notify.success('Documento(s) Adicionados com sucesso.')
      this.getDocuments()
      this.$emit('onSave')

      return true
    },

    getDocuments() {
      if (!this.winner) {
        return
      }
      this.$http.get(`winners/${this.winner.uuid}/documents`)
        .then(data => {
          this.documents = data.map(item => {
            let documents = item.documents.map(doc => DocumentTypes.find(type => type.value === doc).text)
              .join(', ')

            return {
              uuid: item.uuid,
              file: item.file,
              type: item.type,
              documents,
            }
          })
        })
    },
    onShowGalery(item) {
      window.open(item.file)
    },
    onDelete(item) {
      const route = `winners/${this.winner.uuid}/documents/${item.uuid}`
      this.$apiDelete.execute(
        route,
        'Confirma a exclusão do documento?',
        'Excluir documento',
        () => {
          this.$notify.success('Documento excluído com sucesso.')
          this.getDocuments()
        },
      )
    },
    onClose() {
      this.$emit('close')
    },

  },
}
</script>

<style scoped>

</style>
