<template>
  <b-modal
    v-model="showModal"
    :title="title"
    cancel-title="Fechar"
    no-close-on-backdrop
    ok-title="Fechar"
    size="xl"
    scrollable
    ok-only
    @change="onHide"
  >

    <participant-show
      :participant-uuid="participantUuid"
      :show-header="false"
    />
  </b-modal>
</template>

<script>
import ParticipantShow from '@/views/participants/ParticipantShow'

export default {
  name: 'ParticipantDetailModal',
  components: { ParticipantShow },
  props: {
    participantUuid: {
      type: [String],
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: 'Login',
    },
    show: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showModal: false,
    }
  },
  watch: {
    show(value) {
      this.showModal = value
    },
  },
  methods: {
    onHide() {
      this.$emit('onClose', true)
    },
  },
}
</script>

<style scoped>

</style>
