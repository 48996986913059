const DocumentTypes = [
  {
    value: 'id',
    text: 'CPF (ou CNH/RG se contém CPF)',
    rule: 'pf',
    required: 'true',
  },
  {
    value: 'receipt',
    text: 'Recibo assinado',
    required: 'true',
    rule: 'all',
  },
  {
    value: 'proxy',
    text: 'Procuração',
    required: 'false',
    rule: 'all',
  },
  {
    value: 'social_contract',
    text: 'Contrato Social',
    required: 'false',
    rule: 'pf',
  },
  {
    value: 'constitutive_act',
    text: 'Ato constitutivo',
    required: 'false',
    rule: 'pf',
  },
]
export default DocumentTypes
